<template>
    <div class="action_btn">
        <a-popover 
            v-model="visible"
            :title="$t('support.helpAndSupport')" 
            overlayClassName="support_popup"
            trigger="click">
            <template slot="content">
                <StartMenu 
                    :closePopup="closePopup" 
                    :unreadCount="unreadCount" />
            </template>
            <a-badge :count="unreadCount" style="max-width: 32px;" :number-style="{ backgroundColor: '#52c41a' }">
                <a-button
                    type="link"
                    class="text_current">
                    <i class="fi fi-rr-interrogation"></i>
                </a-button>
            </a-badge>
        </a-popover>
    </div>
</template>

<script>
import StartMenu from './StartMenu.vue'
import eventBus from '@/utils/eventBus'
export default {
    components: {
        StartMenu
    },
    data() {
        return {
            visible: false,
            unreadCount: 0
        }
    },
    created() {
        this.getNewsCount()
    },
    methods: {
        closePopup() {
            this.visible = false
        },
        async getNewsCount() {
            try {
                const { data } = await this.$http.get('/news/news/unread_count/')
                if(data?.unread_count) {
                    this.unreadCount = data.unread_count
                }
            } catch(e) {
                console.log(e)
            }
        },
        changeCount() {
            if(this.unreadCount > 0) {
                this.unreadCount -= 1
            } else {
                this.unreadCount = 0
            }
        }
    },
    mounted() {
        eventBus.$on('read_news_count', () => {
            this.changeCount()
        })
    },
    beforeDestroy() {
        eventBus.$off('read_news_count')
    }
}
</script>

<style lang="scss" scoped>
.action_btn{
    &::v-deep{
        .ant-badge-count{
            min-width: 15px;
            height: 15px;
            line-height: 15px;
            top: 9px;
            right: 3px;
            font-size: 10px;
            &.ant-badge-multiple-words{
                padding: 0 4px;
            }
            .ant-scroll-number-only{
                height: 15px;
                p{
                    height: 15px;
                }
            }
        }
        .ant-badge-count{
            font-size: 10px !important;
            min-width: 17px;
            height: 17px;
            padding: 0 6px;
            line-height: 17px;
        }   
    }
}
</style>

<style lang="scss">
.support_popup{
    .ant-popover-inner-content{
        padding: 0px;
    }
}
</style>